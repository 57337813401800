.region-desktop {
  position: relative; }

.region {
  position: relative;
  margin-bottom: 0 !important; }

.MGIB-container {
  position: relative; }

.MGIB-imageBanner {
  width: 100%;
  height: auto; }

.MGIB-container picture {
  display: block; }

.MGIB-container a,
a:hover,
a:focus {
  text-decoration: none;
  cursor: pointer;
  outline: none; }

/* Content ALWAYS hover Image Block */
.MGBIB-content-container,
.MGIB-content-container {
  position: absolute; }

/* END Content Horizontal Alignment PD Attribute */
/* Content Vertical Alignment PD Attribute */
.MGBIB-alignment-vertical-arriba {
  top: 10%; }
  .MGBIB-alignment-vertical-arriba.button-container {
    top: 15%; }
  @media (min-width: 992px) {
    .MGBIB-alignment-vertical-arriba {
      top: 5%; }
      .MGBIB-alignment-vertical-arriba.button-container {
        top: 10%; } }

.MGBIB-alignment-vertical-centro {
  top: 50%; }
  .MGBIB-alignment-vertical-centro.button-container {
    top: 55%; }

.MGBIB-alignment-vertical-abajo {
  top: 90%; }
  .MGBIB-alignment-vertical-abajo.button-container {
    top: 95%; }
  @media (min-width: 992px) {
    .MGBIB-alignment-vertical-abajo {
      top: 90%; }
      .MGBIB-alignment-vertical-abajo.button-container {
        top: 95%; } }

.MGIB-alignment-vertical-arriba {
  top: 27%; }

.MGIB-alignment-vertical-centro {
  top: 50%; }

.MGIB-alignment-vertical-abajo {
  top: 73%; }

/* END Content Horizontal Alignment PD Attribute */
.MGIB-text h1,
.MGIB-text h2 {
  font-size: 28px; }
  @media (min-width: 992px) {
    .MGIB-text h1,
    .MGIB-text h2 {
      font-size: 34px; } }

.MGIB-text h3 {
  font-size: 6vw; }
  @media screen and (min-width: 768px) {
    .MGIB-text h3 {
      font-size: 2.5vw; } }

/* Opacity PD Attribute */
.MGIB-opacity {
  -webkit-filter: brightness(60%);
          filter: brightness(60%); }

/* END Opacity PD Attribute */
/* Button PD Attribute */
.MGIB-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%; }

.MGIB-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8px 20px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  max-width: 150px;
  font-size: 18px; }
  @media (min-width: 992px) {
    .MGIB-button {
      font-size: 24px; } }
  .MGIB-button.MGIB-buttonStyle-fino {
    height: 25px; }
  .MGIB-button.MGIB-buttonStyle-ancho {
    height: 35px; }
  .MGIB-button:only-child:not(.single) {
    min-width: 35%; }
  .MGIB-button.single {
    width: 200px; }
  .MGIB-button.underline {
    text-decoration: underline; }

/* END Button PD Attribute */
/* Zoom PD Attribute */
.MGIB-image-hover {
  -webkit-transition: 1.15s;
  transition: 1.15s; }

.MGIB-image-hover:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15); }

.MGIB-image-hover-container {
  overflow: hidden;
  display: block; }

/* END Zoom PD Attribute */
/* Content Horizontal Alignment PD Attribute */
.MGBIB-alignment-centro {
  text-align: center;
  width: 100%; }

.MGBIB-alignment-izquierda,
.MGBIB-alignment-derecha {
  text-align: center;
  width: 100%; }
  @media (min-width: 992px) {
    .MGBIB-alignment-izquierda,
    .MGBIB-alignment-derecha {
      width: auto; } }

@media (min-width: 992px) {
  .MGBIB-alignment-izquierda {
    left: 1%; } }

@media (min-width: 992px) {
  .MGBIB-alignment-derecha {
    right: 1%; } }

.MGIB-alignment-centro,
.MGIB-alignment-izquierda,
.MGIB-alignment-derecha {
  text-align: center;
  width: 100%;
  right: 0; }

.MGIB-alignment-centro,
.MGIB-alignment-izquierda {
  left: 0; }

/* END Content Horizontal Alignment PD Attribute */
/* Image hover content text */
.MGIB-contentImageTop {
  width: 90%;
  display: block;
  margin: auto; }
  @media (max-width: 768px) {
    .MGIB-contentImageTop {
      position: absolute;
      margin: auto;
      top: 15%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      width: 50%; } }

/* END hover under content text */
/* Image under content text */
.MGIB-contentImageBottom {
  width: 40%;
  display: block;
  margin: auto; }

/* END Image under content text */
/* Content under Image Block only in mobile Format */
@media (min-width: 768px) {
  .MGBIB-content-container-mobileBottom,
  .MGIB-content-container-mobileBottom {
    position: absolute;
    margin: auto;
    line-height: 140%;
    font-size: 16px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

@media (max-width: 768px) {
  .MGBIB-content-container-mobileBottom h1,
  .MGBIB-content-container-mobileBottom h2,
  .MGBIB-content-container-mobileBottom h3,
  .MGBIB-content-container-mobileBottom p,
  .MGIB-content-container-mobileBottom h1,
  .MGIB-content-container-mobileBottom h2,
  .MGIB-content-container-mobileBottom h3,
  .MGIB-content-container-mobileBottom p {
    color: black; } }

/* Content ALWAYS under Image Block */
.MGBIB-content-container-fixedBottom,
.MGIB-content-container-fixedBottom {
  display: block; }
  .MGBIB-content-container-fixedBottom h1,
  .MGBIB-content-container-fixedBottom h2,
  .MGBIB-content-container-fixedBottom h3,
  .MGBIB-content-container-fixedBottom p,
  .MGIB-content-container-fixedBottom h1,
  .MGIB-content-container-fixedBottom h2,
  .MGIB-content-container-fixedBottom h3,
  .MGIB-content-container-fixedBottom p {
    color: black; }

/* Background Content PD Attribute */
.MGIB-background-blanco-intenso {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4%; }

.MGIB-background-blanco-medio {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 4%; }

/* END Background Content PD Attribute */
/* Espacios entre componentes PageDesigner */
